@font-face {
  font-family: 'Futura';
  font-style: normal;
  src: local('Futura'), url("fonts/Futura-Bk-Book.ttf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: 'Futura Light';
  font-style: normal;
  src: local('Futura-Light'), url("fonts/Futura-Light.ttf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'Futura-Medium';
  src: local('Futura-Medium'), url("./fonts/Futura-Medium.ttf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Futura-Bold';
  src: local('Futura-Bold'), url("./fonts/Futura-Bold.ttf") format("opentype");
  font-style: normal;
  font-weight: 800;
}

body {
  margin: 0;
  font-family: 'Futura', Futura-Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  font-size: 0.9rem;
}

* {
  font-family: 'Futura', sans-serif!important;
}

h1, h2, h3, h4, h5, h6, strong {
  font-family: 'Futura-Bold', sans-serif !important;
}

.MuiTableCell-root.MuiTableCell-body {
  padding: 0;
}

.MuiCardContent-root:last-child {
  padding: 0 !important;
}

.MuiTimeline-root > .MuiTimelineItem-root :first-child > .MuiTimelineSeparator-root > .MuiTimelineConnector-root {
   background-color: red!important;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 1;
}

[data-shrink="true"] {
  padding: 0 8px 0 4px!important;
}

.MuiInputBase-root:after,
.MuiSelect-root:after {
 border-bottom: unset!important;
}
.MuiTimelineItem-root:first-child .MuiTimelineConnector-root:first-child{
  background-color: transparent;
}
.MuiTimelineItem-root:last-child .MuiTimelineConnector-root:last-child{
  background-color: transparent;
}